<template>
    <div class="py-10 lg:py-24 bg-primary-600">
        <div class="container">
            <div class="grid grid-cols-2 lg:grid-cols-6 gap-8 xl:gap-15">
                <div class="col-span-2">
                    <div class="mb-4 lg:mb-12">
                        <app-image :id="dataConfig?.app_configs?.logo" width="200" height="60" alt="logo" />
                    </div>
                    <div class="text-white opacity-58 text-sm !leading-[1.86] mb-0 sm:mb-4 lg:mb-15">
                        {{ t('FOOTER.DESCRIPTION') }}
                    </div>
                </div>
                <template v-for="(item, index) in menuFooter?.menu_item">
                    <div class="col-span-1"
                        :class="index === menuFooter?.menu_item?.length - 1 ? 'col-span-2' : 'col-span-1'">
                        <div class="text-white text-2xl font-bold !leading-[1.33] mb-4 lg:mb-10">{{ item?.name }}</div>
                        <ul class="flex flex-col gap-4 lg:gap-8">
                            <li v-for="(child, indexChild) in item?.children" class="group">
                                <nuxt-link :to="child?.url ?? '/'" class="flex items-center gap-2.5 group">
                                    <div v-if="child?.icon_image" class="min-w-10.5 w-10.5 h-10.5">
                                        <app-image :id="child?.icon_image"
                                            class="w-full h-full duration-200 group-hover:scale-105" width="70"
                                            height="70" :alt="child?.name" />
                                    </div>
                                    <div
                                        class="text-white opacity-58 text-sm !leading-[1.86] duration-200 group-hover:opacity-100">
                                        {{ child?.name }}</div>
                                </nuxt-link>
                            </li>
                        </ul>
                    </div>
                </template>
            </div>
            <div class="mt-8 lg:mt-16 mb-8 w-full h-0.25 bg-white opacity-20"></div>
            <div class="text-white opacity-58 text-sm !leading-[1.86]-">All rights are reserved © 2024 Skylight.</div>
        </div>
    </div>
</template>

<script setup lang="ts">
const route = useRoute()

const { t } = useI18n();

const appConfig = useState("app_config")
const dataConfig = appConfig.value?.data

const menuFooter = computed(() => dataConfig?.menus?.find(item => item.name === `footer_${route.params?.lang}`))
</script>
